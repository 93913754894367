<template>
  <!-- 注册页面-租户 -->
  <div>
		<header v-if="isApp" class="app">
			<div class="app-header-container" id="appHeader">
				<div class="header-info">
					<div class="plat-info">
						<div class="left">
							<img :src="platInfo.logo_url" @click="$utils.goIndexPage" class="logo" />
							<span>{{platInfo.name}}</span>
						</div>
						<img src="@/assets/img/login/user.png" @click="$router.push('/loginIndex')" class="icon-user" >
					</div>
					<div class="welcome-text-info">
					  <div class="welcome" v-if="loginInfo.disseminate_type=='text'">
					    {{loginInfo.text}}
					  </div>
					  <!-- <div class="left" v-if="loginInfo.disseminate_type=='img'">
					    <img :src="loginInfo.img.attachment_path" >
					  </div> -->
					</div>
				</div>
				
				<el-carousel indicator-position="outside" class="carousel-box" height="100%" :interval="3000">
				  <el-carousel-item v-for="(item,index) in imgArr" :key="index">
				    <img :src="item.attachment_path"  style="height: 100%;width:100%;object-fit: cover;">
				  </el-carousel-item>
				</el-carousel>
			</div>
			
		</header>
    <header v-else>
      <div class="header-container">
        <div class="plat-info">
          <img :src="platInfo.logo_url" @click="$utils.goIndexPage" class="logo" />
          <span>{{platInfo.name}}</span>
        </div>
        <div class="left" @click="gotoLinks(loginInfo.link)">
          <div class="welcome" v-if="loginInfo.disseminate_type=='text'">
            {{loginInfo.text}}
          </div>
          <div class="left" v-if="loginInfo.disseminate_type=='img'">
            <img :src="loginInfo.img.attachment_path" >
          </div>
        </div>
        <span class="spn"> 已有账号？<span @click="$router.push('/loginIndex')" class="point">请登录>></span> </span>
      </div>
    </header>
    <div :class="['mains',isApp?'app':'']" id="rentBg" >
      <router-view class="router"></router-view>
			<el-carousel indicator-position="outside" class="carousel-box" height="100%" :interval="3000" v-if="!isApp">
			  <el-carousel-item v-for="(item,index) in imgArr" :key="index">
			    <img :src="item.attachment_path"  style="height: 100%;width:100%;object-fit: cover;">
			  </el-carousel-item>
			</el-carousel>
			<!-- <img v-if="isApp" alt="" id="mainBgImg" class="bg-img"> -->
    </div>
    <footers :navBar="navBar" v-if="!isApp"></footers>
  </div>
</template>

<script>
import footers from '@/components/footers.vue'
export default {
  name: "RegisterPlatform",
  components:{
    footers
  },
  data(){
    return {
      navBar:[],
      loginInfo:{},
      platInfo:{},
			isApp:false,
			imgArr:[],
    }
  },
  methods:{
	 gotoLinks(val){
      console.log(val);
      window.open(val)
    },
    getLoginSet(){
			let href = location.href;
			// to do 员工邀请入驻调用设置
			let url = '/setting/public/station_reglogin';
			if(/\/register\/staff/.test(href)){
				console.log("员工登录接口");
				url = '/setting/public/reglogin';
				this.$axios.get('/setting/public/reglogin') .then((res) => {
				    if (res.code == 200) {
				      let data = res.data;
				      let loginSet = {};
				      data.map(item=>{
				        //背景图片
								let imgArr = [];
				        if(item.key == 'login_reg_pic_coll'){
				          let dataArr = item.value.items;
				          for(let i=0,len=dataArr.length;i<len;i++){
				            if(dataArr[i].status == 1){
											imgArr.push(dataArr[i])
				            }
				          }
									this.imgArr = imgArr;
				        }
				        //底部导航
				        if(item.key=='login_reg_bottom_nav'){
				          if(item.value && item.value.items && item.value.items instanceof Array){
				            this.navBar = item.value.items.filter(its=>its.status==1);
				          }
				        }
				        //头部欢迎语
				        if(item.key == 'login_reg_base_setting'){
				          this.loginInfo = item.value;
				        }
				      })
							let appHeader =  document.getElementById('appHeader');
							if(appHeader &&loginSet.status){
							  appHeader.style.background=`url(${loginSet.attachment_path}) no-repeat center`;
							  appHeader.style.backgroundSize='cover'
							}
				    }
				  })
				  .catch((err) => {
				    console.log(err);
				    err.msg && this.$message.error(err.msg || "系统错误,联系管理员");
				  });
				
			}else{
				this.$axios.get('/setting/public/station_reglogin') .then((res) => {
				    if (res.code == 200) {
				      let data = res.data;
				      let loginSet = {};
				      data.map(item=>{
				        //背景图片
								let imgArr = [];
				        if(item.key == 'station_login_reg_pic_coll'){
				          let dataArr = item.value.items;
				          for(let i=0,len=dataArr.length;i<len;i++){
				            if(dataArr[i].status == 1){
											imgArr.push(dataArr[i])
				            }
				          }
									this.imgArr = imgArr;
				        }
				        //底部导航
				        if(item.key=='station_login_reg_bottom_nav'){
				          if(item.value && item.value.items && item.value.items instanceof Array){
				            this.navBar = item.value.items.filter(its=>its.status==1);
				          }
				        }
				        //头部欢迎语
				        if(item.key == 'station_login_reg_base_setting'){
				          this.loginInfo = item.value;
				        }
				      })
							let appHeader =  document.getElementById('appHeader');
							if(appHeader &&loginSet.status){
								// console.log('app',appHeader);
							  appHeader.style.background=`url(${loginSet.attachment_path}) no-repeat center`;
							  appHeader.style.backgroundSize='cover'
							}
				    }
				  })
				  .catch((err) => {
				    console.log(err);
				    err.msg && this.$message.error(err.msg || "系统错误,联系管理员");
				  });
				
			}
      
    },
		isMobile() {
		  var userAgentInfo = navigator.userAgent;
		  var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];
		  var mobile_flag = false;
		  for (var v = 0; v < mobileAgents.length; v++) {
		      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
		          mobile_flag = true;
		          break;
		      }
		  }
		   var screen_width = window.screen.width;
		   var screen_height = window.screen.height;   
		   if(screen_width < 500 && screen_height < 800){
		       mobile_flag = true;
		   }
		   return mobile_flag;
		}
  },
  created() {
		this.isApp = this.isMobile();
    this.$utils.getPlatformInfo().then(res=>{
      if(res.data){
        this.platInfo = res.data;
      }
    })
  },
  mounted() {
		if(this.isApp){
			// 1rem  相当于750尺寸设计图的 20px;
			//兼容pc显示
			(function(){
				var html=document.querySelector('html');
				html.style.fontSize=window.innerWidth/750 * 20 +'px'
				window.onresize=function(){
					html.style.fontSize=window.innerWidth/750 * 20 +'px'
				}
			})();
			var fun = function (doc, win) {
			    var docEl = doc.documentElement,
			        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
			        recalc = function () {
			            var clientWidth = docEl.clientWidth; 
			            if (!clientWidth) return;
			            //这里是假设在750px宽度设计稿的情况下，1rem = 100px；
			            //可以根据实际需要修改
			            docEl.style.fontSize = 20 * (clientWidth / 750) + 'px';
			        };
			    if (!doc.addEventListener) return;
			    win.addEventListener(resizeEvt, recalc, false);
			    doc.addEventListener('DOMContentLoaded', recalc, false);
			}
			fun(document, window)
		}
    this.getLoginSet();
  },
};
</script>

<style lang="scss" scoped>
.app-header-container{
	font-size: calc( 100vw / 750 * 20);
	padding: 1.5em 1em;
	box-sizing: border-box;
	height: 20.5em;
	.header-info{
		position: relative;
		padding: 1.5em 1em;
		z-index: 5;
	}
	.carousel-box{
		position: absolute;
		top:0;
		left:0;
		height: 20.5rem;
		width: 100vw;
		z-index: 3;
	}
	.plat-info{
		display: flex;
		font-size: 1.6em;
		color:#FFFFFF;
		height: 3.2em;
		align-items: center;
		justify-content: space-between;
		.left{
			display: flex;
			align-items: center;
			.logo{
				width:3em;
				margin-right:1em;
				height:auto;
			}
		}
		
		.icon-user{
			width:1em;
			height:1em;
		}
	}
	.welcome-text-info{
		margin-top: 1em;
		font-size: 1.8em;
		color:#FFFFFF;
		text-align: center;
		
		.left{
			img{
				height:2em;
				width:auto;
			}
		}
	}
	.login-app{
		text-align: right;
		font-size: 1.3em;
		color:#666;
		.point{
			color:#F72626;
		}
	}
	
	
}
header {
  background-color: #fff;
  height: 64px;
	&.app{
		height:auto;
	}
  .header-container {
    width: 1200px;
    height: 64px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .plat-info{
      display: flex;
      align-items: center;
      font-size: 22px;
      .logo{
        height:30px;
        margin-right:24px;
        cursor: pointer;
      }
    }
    .left{
      display: flex;
      align-items: center;
      .welcome {
        font-size: 16px;
        white-space: pre;
      }
      img {
        width: auto;
        height: 40px;
        margin-right: 12px;
      }
    }
    .spn {
      flex:0;
      position: absolute;
      right:0;
      bottom:-30px;
      font-size: 14px;
      span {
        color: #f74848;
      }

    }
  }
}
.point{
  cursor: pointer;
}
.mains{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height:calc(100vh - 192px);
	position: relative;
	&.app{
		align-items: unset;
		min-height: unset;
	}
	.carousel-box{
		position: absolute;
		z-index: 3;
		flex:0;
		top:0;
		left:0;
		width:100%;
		height: 100%;
		overflow: hidden;
	}
	.bg-img{
		position: absolute;
		z-index: 3;
		top:0;
		left:0;
		width:100%;
		height: 800px;
		-webkit-filter: blur(15px);
		-moz-filter: blur(15px);
		-o-filter: blur(15px);
		-ms-filter: blur(15px);
		filter: blur(15px);
	}
	.router{
		position: relative;
		z-index: 4;
	}
}
</style>
